import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

function ModalTableProduct(props) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    produk: "",
    aplikasi: "",
    ukuran: "",
    fitur: "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    // Mengirimkan data ke server atau tindakan lain sesuai kebutuhan Anda
    console.log(data);

    // Menutup modal
    handleClose();
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Buka Modal
      </Button>

      <Modal open={props.open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">Form Pengisian Tabel</Typography>
          <form>
            <TextField
              fullWidth
              label="Produk"
              name="produk"
              value={data.produk}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Aplikasi"
              name="aplikasi"
              value={data.aplikasi}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Ukuran"
              name="ukuran"
              value={data.ukuran}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Fitur"
              name="fitur"
              value={data.fitur}
              onChange={handleChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ marginTop: 2 }}
            >
              Simpan
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalTableProduct;
