import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Fade,
  Backdrop,
  Box,
  Divider,
  Grid,
  Button,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TextField,
} from "@mui/material";
import {
  addProduct,
  editProduct,
  fetchDataBrand,
  fetchDataCategoryProduct,
} from "../../store/action";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BaseURL } from "../../config/API";

function ModalDetailProduct({ open, close, data }) {
  console.log("Apaaa? ", data.TableSpesifikasis);
  const [edited, setEdited] = useState(false);
  // pengelompokan
  // Mengelompokkan nilai berdasarkan kolom namaKolom
  // const groupedData = {};
  // data.TableSpesifikasis.forEach((row) => {
  //   const columnName = row.SpesKolom.namaKolom;
  //   if (!groupedData[columnName]) {
  //     groupedData[columnName] = [];
  //   }
  //   groupedData[columnName].push(row.nilai);
  // });

  // // Membuat array kolom unik
  // const uniqueColumns = Array.from(
  //   new Set(data.TableSpesifikasis.map((row) => row.SpesKolom.namaKolom))
  // );
  // Mengelompokkan nilai berdasarkan kolom namaKolom
  // Mengelompokkan nilai berdasarkan kolom namaKolom
  // Mengelompokkan nilai berdasarkan kolom namaKolom
  // Mengelompokkan nilai berdasarkan kolom namaKolom
  // Mengelompokkan nilai berdasarkan kolom namaKolom
  // Mengelompokkan nilai berdasarkan kolom namaKolom
  // Lama
  // const groupedData = {};
  // data.TableSpesifikasis.forEach((row) => {
  //   const columnName = row.SpesKolom.namaKolom;
  //   if (!groupedData[columnName]) {
  //     groupedData[columnName] = [];
  //   }
  //   groupedData[columnName].push(row.nilai);
  // });

  // // Membuat array kolom unik
  // const uniqueColumns = Array.from(
  //   new Set(data.TableSpesifikasis.map((row) => row.SpesKolom.namaKolom))
  // );
  // Mengelompokkan nilai berdasarkan kolom namaKolom
  // Mengelompokkan nilai berdasarkan kolom namaKolom
  // Mengelompokkan nilai berdasarkan kolom namaKolom
  const groupedData = {};
  data.TableSpesifikasis.forEach((row) => {
    const columnName = row.SpesKolom.namaKolom;
    if (!groupedData[columnName]) {
      groupedData[columnName] = [];
    }
    groupedData[columnName].push(row.nilai);
  });

  // Membuat array kolom unik
  const uniqueColumns = Array.from(
    new Set(data.TableSpesifikasis.map((row) => row.SpesKolom.namaKolom))
  );

  // Menentukan panjang maksimum dari setiap kolom
  const maxRows = Math.max(
    ...uniqueColumns.map((col) => groupedData[col].length)
  );

  // Membangun struktur data baru dengan penanganan rowspan
  const tableData = Array.from({ length: maxRows }).map((_, rowIndex) => {
    return uniqueColumns.map((uniqueColumn) => {
      const cellValues = groupedData[uniqueColumn];
      const cellValue = cellValues[rowIndex];

      let rowspan = 1;

      if (cellValue === "" && rowIndex < cellValues.length - 1) {
        // Hanya cek rowspan untuk sel yang bukan baris terakhir
        rowspan =
          cellValues.slice(rowIndex + 1).findIndex((value) => value !== "") + 1;
      }

      return {
        value: cellValue !== undefined ? cellValue : "",
        rowspan: rowspan,
      };
    });
  });

  console.log("uniqueColumns", uniqueColumns);
  console.log("groupedData", groupedData);
  console.log("HHHHHH", groupedData["Ukuran"]);
  // Menyusun data untuk render tabel
  // Menyusun data untuk render tabel
  // Menyusun data untuk render tabel
  // const tableData = Array.from({
  //   length: Math.max(...Object.values(groupedData).map((arr) => arr.length)),
  // }).map((index, p) => {
  //   const rowData = uniqueColumns.map((uniqueColumn, i) => {
  //     console.log("INDEXX", p);
  //     const cellValue =
  //       groupedData[uniqueColumn][p] !== undefined
  //         ? groupedData[uniqueColumn][p]
  //         : "";
  //     const shouldMerge =
  //       p > 0 &&
  //       groupedData[uniqueColumn][p] === "" &&
  //       !groupedData[uniqueColumn].slice(0, p).some((value) => value !== "");
  //     return {
  //       value: cellValue,
  //       shouldMerge: shouldMerge,
  //     };
  //   });
  //   return rowData;
  // });
  // ATAS BETUL
  // Menyusun data untuk render tabel
  //LAMA
  // const tableData = Array.from({
  //   length: Math.max(...Object.values(groupedData).map((arr) => arr.length)),
  // }).map((index, p) => {
  //   const rowData = uniqueColumns.map((uniqueColumn) => {
  //     const cellValue =
  //       groupedData[uniqueColumn][p] !== undefined
  //         ? groupedData[uniqueColumn][p]
  //         : "";
  //     const shouldMerge =
  //       p > 0 &&
  //       groupedData[uniqueColumn][p] === "" &&
  //       !groupedData[uniqueColumn].slice(0, p).some((value) => value !== "");
  //     return {
  //       value: cellValue,
  //       shouldMerge: shouldMerge,
  //     };
  //   });
  //   return rowData;
  // });
  // console.log("tableData", tableData);
  console.log("uniqueColumns", uniqueColumns);
  const handleValueChange = (newValue, rowIndex, colIndex) => {
    // Salin data tabel agar tidak mengubah data asli
    const updatedTableData = [...tableData];
    // Perbarui nilai pada posisi yang sesuai dengan indeks baris dan kolom
    updatedTableData[rowIndex][colIndex].value = newValue;
    // Setel data tabel yang diperbarui ke dalam state atau lakukan tindakan lain yang diperlukan
    // setTableData(updatedTableData);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          style={{
            backgroundColor: "white",
            boxShadow: 5,
            overflow: "hidden",
            paddingBottom: 50,
            position: "relative",
            padding: 30,
            borderRadius: 10,
            width: "90%",
            maxWidth: 700,
            maxHeight: "90%",
            overflowY: "auto",
          }}
        >
          <h3 style={{ marginTop: 0, marginBottom: 5 }}>Detail {data.name}</h3>
          <Divider />
          <TableContainer component={Paper}>
            {/* <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {uniqueColumns.map((columnName) => (
                    <TableCell key={columnName}>{columnName}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({
                  length: Math.max(
                    ...Object.values(groupedData).map((arr) => arr.length)
                  ),
                }).map((_, index) => (
                  <TableRow key={index}>
                    {uniqueColumns.map((uniqueColumn) => (
                      <TableCell key={uniqueColumn}>
                        {groupedData[uniqueColumn][index] || "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table> */}
            {/* <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {uniqueColumns.map((columnName) => (
                    <TableCell key={columnName}>{columnName}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((rowData, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {rowData.map(({ value, shouldMerge }, colIndex) => (
                      <TableCell
                        key={colIndex}
                        rowSpan={shouldMerge ? 0 : 1}
                        style={{
                          visibility: shouldMerge ? "hidden" : "visible",
                          borderBottom: shouldMerge ? "none" : "inherit",
                        }}
                      >
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table> */}
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {uniqueColumns.map((columnName) => (
                    <TableCell key={columnName}>{columnName}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((rowData, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {rowData.map(({ value, shouldMerge }, colIndex) => (
                      <TableCell
                        key={colIndex}
                        rowSpan={shouldMerge ? 0 : 1}
                        style={{
                          visibility: shouldMerge ? "hidden" : "visible",
                          borderBottom: shouldMerge ? "none" : "inherit",
                        }}
                      >
                        {edited === false && value}
                        {shouldMerge ? null : (
                          <TextField
                            variant="standard"
                            value={value}
                            onChange={(e) =>
                              handleValueChange(
                                e.target.value,
                                rowIndex,
                                colIndex
                              )
                            }
                          />
                        )}
                        <Button
                          variant="contained"
                          onClick={() => setEdited(true)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {/* // Render tabel dengan data yang telah digabungkan */}
          </TableContainer>
          {/* <table>
            <thead>
              <tr>
                {uniqueColumns.map((columnName) => (
                  <th key={columnName}>{columnName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  {rowData.map(({ value, rowspan }, colIndex) => (
                    <td key={colIndex} rowSpan={rowspan}>
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table> */}

          <Grid style={{ textAlign: "right", marginTop: 20 }}>
            <Button onClick={close} color="inherit" style={{ marginRight: 20 }}>
              Close
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}

const mapDispatchToProps = {
  addProduct,
  editProduct,
  fetchDataBrand,
  fetchDataCategoryProduct,
};

const mapStateToProps = ({ dataBrand, dataCategoryProduct }) => {
  return {
    dataBrand,
    dataCategoryProduct,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetailProduct);
