import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from './store/index'

import Navsidebar from "./components/Navsidebar";

import { makeStyles } from "@mui/styles";

import Routes from "./navigation/routes";
import './App.css';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '1920px',
  },
}));

function App() {
  const classes = useStyles();

  return (
    <Provider store={store}>
      <Router>
        <div className={classes.root}>
          <Navsidebar />
          <Routes />
        </div>
      </Router>
    </Provider>
  )
}

export default App;
