import { createStore, applyMiddleware } from "redux";
import reducer from "./reducer";
import { API } from "../config/API";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

const api = (store) => (next) => async (action) => {
  let token = Cookies.get("BPWTKNCMS");

  // LOCATION
  if (action.type === "FETCH_DATA_LOCATION") {
    try {
      let { data } = await API.get(`/our-location${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataLocation: data.data || [],
          totalLocation: data.totalLocation || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Description Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_LOCATION") {
    try {
      await API.post(`/our-location`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add Description Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data Description Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_LOCATION") {
    try {
      await API.delete(`/our-location/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete Description Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Description Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_LOCATION") {
    try {
      await API.put(`/our-location/${action.payload.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit Description Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Edit Data Description Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // CONTACT
  else if (action.type === "FETCH_DATA_CONTACT") {
    try {
      let { data } = await API.get(`/contact-us${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataContact: data.data || [],
          totalContact: data.totalContactUs || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Contact Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_CONTACT") {
    try {
      await API.delete(`/contact-us/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });

      Swal.fire({
        title: "Delete Contact Us Success",
        icon: "success",
      });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Contact Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // BRAND
  else if (action.type === "FETCH_DATA_BRAND") {
    try {
      let { data } = await API.get(`/brand${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataBrand: data.data || [],
          totalBrand: data.totalBrand || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Brand Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_BRAND") {
    try {
      await API.post(`/brand`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add Brand Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data Brand Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_BRAND") {
    try {
      await API.delete(`/brand/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete Brand Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Brand Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_BRAND") {
    try {
      await API.put(`/brand/${action.payload.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit Brand Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Edit Data Brand Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // CATEGORY PRODUCT
  else if (action.type === "FETCH_DATA_CATEGORY_PRODUCT") {
    try {
      let { data } = await API.get(`/category-product${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataCategoryProduct: data.data || [],
          totalCategoryProduct: data.totalCategoryProduct || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Category Product Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_CATEGORY_PRODUCT") {
    try {
      await API.post(`/category-product`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add Category Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data Category Product Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_CATEGORY_PRODUCT") {
    try {
      await API.delete(`/category-product/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete Category Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Category Product Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_CATEGORY_PRODUCT") {
    try {
      await API.put(`/category-product/${action.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit Category Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Edit Data Category Product Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // PRODUCT
  else if (action.type === "FETCH_DATA_PRODUCT") {
    try {
      let { data } = await API.get(`/product${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataProduct: data.data || [],
          totalProduct: data.totalProduct || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Product Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_PRODUCT") {
    try {
      await API.post(`/product`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add Product Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data Product Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_PRODUCT") {
    try {
      await API.delete(`/product/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete Product Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Product Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_PRODUCT") {
    try {
      await API.put(`/product/${action.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit Product Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Edit Data Product Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // SERVICE
  else if (action.type === "FETCH_DATA_SERVICE") {
    try {
      let { data } = await API.get(`/service${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataService: data.data || [],
          totalService: data.totalService || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Service Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_SERVICE") {
    try {
      await API.post(`/service`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add Service Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data Service Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_SERVICE") {
    try {
      await API.delete(`/service/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete Service Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Service Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_SERVICE") {
    try {
      await API.put(`/service/${action.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit Service Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Edit Data Service Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // ABOUT US
  else if (action.type === "FETCH_DATA_ABOUT_US") {
    try {
      let { data } = await API.get(`/about-us${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataAboutUs: data.data || [],
          totalAboutUs: data.totalAboutUs || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data About Us Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_ABOUT_US") {
    try {
      await API.post(`/about-us`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add About Us Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data About Us Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_ABOUT_US") {
    try {
      await API.delete(`/about-us/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete About Us Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data About Us Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_ABOUT_US") {
    try {
      await API.put(`/about-us/${action.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit About Us Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Edit Data About Us Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // EVENT
  else if (action.type === "FETCH_DATA_EVENT") {
    try {
      let { data } = await API.get(`/event${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataEvent: data.data || [],
          totalEvent: data.totalEvent || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Event Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_EVENT") {
    try {
      await API.post(`/event`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add Event Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data Event Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_EVENT") {
    try {
      await API.delete(`/event/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete Event Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Event Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_EVENT") {
    try {
      await API.put(`/event/${action.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit Event Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Edit Data Event Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // NEWS
  else if (action.type === "FETCH_DATA_NEWS") {
    try {
      let { data } = await API.get(`/news${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataNews: data.data || [],
          totalNews: data.totalNews || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data News Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_NEWS") {
    try {
      await API.post(`/news`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add News Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data News Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_NEWS") {
    try {
      await API.delete(`/news/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete News Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data News Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_NEWS") {
    try {
      await API.put(`/news/${action.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit News Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Edit Data News Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // BLOGS
  else if (action.type === "FETCH_DATA_BLOGS") {
    try {
      let { data } = await API.get(`/news${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataBlogs: data.data || [],
          totalBlogs: data.totalBlogs || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Blogs Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_BLOGS") {
    try {
      await API.post(`/news`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add Blogs Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data Blogs Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_BLOGS") {
    try {
      await API.delete(`/news/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete Blogs Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Blogs Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_BLOGS") {
    try {
      await API.put(`/news/${action.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit Blogs Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Edit Data Blogs Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // BANNER
  else if (action.type === "FETCH_DATA_BANNER") {
    try {
      let { data } = await API.get(`/banner${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataBanner: data.data || [],
          totalBanner: data.totalBanners || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Banner Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_BANNER") {
    try {
      await API.post(`/banner`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add Banner Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data Banner Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_BANNER") {
    try {
      await API.delete(`/banner/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete Banner Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Banner Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_BANNER") {
    try {
      await API.put(`/banner/${action.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit Banner Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Edit Data Banner Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // BANNER
  else if (action.type === "FETCH_DATA_SPESIFIKASI") {
    try {
      let { data } = await API.get(`/spesifikasi${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataSpesifikasi: data.data || [],
          totalSpesifikasi: data.totalSpesifikasi || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Spesifikasi Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_SPESIFIKASI") {
    try {
      await API.post(`/spesifikasi`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add Spesifikasi Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data Spesifikasi Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_SPESIFIKASI") {
    try {
      await API.delete(`/spesifikasi/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete Spesifikasi Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Spesifikasi Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_SPESIFIKASI") {
    try {
      await API.put(`/spesifikasi/${action.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit Spesifikasi Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Edit Data Spesifikasi Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // TABLE SPESIFIKASI
  else if (action.type === "FETCH_DATA_TABLE_SPESIFIKASI") {
    try {
      let { data } = await API.get(`/tablespesifikasi${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataTableSpesifikasi: data.data || [],
          totalTableSpesifikasi: data.totalTableSpesifikasi || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Spesifikasi Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_TABLE_SPESIFIKASI") {
    try {
      await API.post(`/tablespesifikasi`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add Spesifikasi Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data Spesifikasi Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_TABLE_SPESIFIKASI") {
    try {
      await API.delete(`/tablespesifikasi/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete Spesifikasi Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Spesifikasi Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_TABLE_SPESIFIKASI") {
    try {
      await API.put(`/tablespesifikasi/${action.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit Spesifikasi Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Edit Data Spesifikasi Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // SPES KOLOM
  else if (action.type === "FETCH_DATA_SPES_KOLOM") {
    try {
      let { data } = await API.get(`/speskolom${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataSpesKolom: data.data || [],
          totalSpesKolom: data.totalSpesKolom || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Spesifikasi Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_SPES_KOLOM") {
    try {
      await API.post(`/speskolom`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add Nama Kolom Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Nama Kolom Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_SPES_KOLOM") {
    try {
      await API.delete(`/speskolom/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete Spesifikasi Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Spesifikasi Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_SPES_KOLOM") {
    try {
      await API.put(`/speskolom/${action.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit Spesifikasi Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Edit Data Spesifikasi Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  }
  // META TAG
  else if (action.type === "FETCH_DATA_META_TAG") {
    try {
      let { data } = await API.get(`/meta-tag${action.payload}`);

      next({
        type: "FETCH_DATA_SUCCESS",
        payload: {
          dataMetaTag: data.data || [],
          totalMetaTag: data.totalMetaTags || 0,
        },
      });
    } catch (err) {
      Swal.fire({
        title: "Get Data Meta Tag Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "ADD_META_TAG") {
    try {
      await API.post(`/meta-tag`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Add Meta Tag Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Add Data Meta Tag Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "DELETE_META_TAG") {
    try {
      await API.delete(`/meta-tag/${action.payload}`, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Delete Meta Tag Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      Swal.fire({
        title: "Delete Data Meta Tag Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else if (action.type === "EDIT_META_TAG") {
    try {
      await API.put(`/meta-tag/${action.id}`, action.payload, {
        headers: { token: `Bearer ${token}` },
      });
      Swal.fire({
        title: "Edit Meta Tag Success",
        icon: "success",
      });
      next({ type: "SUCCESS" });
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Edit Data Meta Tag Failed",
        text: "Please try again !",
        icon: "error",
      });
      next({
        type: "FETCH_DATA_ERROR",
        payload: err,
      });
    }
  } else {
    next(action);
  }
};

const store = createStore(reducer, applyMiddleware(api));

export default store;
