import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Fade,
  Backdrop,
  Box,
  Divider,
  Grid,
  Button,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import {
  addTableSpesifikasi,
  editTableSpesifikasi,
  fetchDataProduct,
  fetchDataSpesKolom,
  addSpesKolom,
} from "../../store/action";
import Swal from "sweetalert2";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { API } from "../../config/API";

function ModalAddProduct({
  open,
  close,
  addTableSpesifikasi,
  addSpesKolom,
  refresh,
  editTableSpesifikasi,
  data,
  fetchDataProduct,
  fetchDataSpesKolom,
  dataProduct,
  dataSpesKolom,
}) {
  const [proses, setProses] = useState(false);
  const [input, setInput] = useState({
    idProduct: null,
    namaProduct: null,
    aplikasi: null,
    ukuran: null,
    fitur: null,
  });
  const [allProduct, setAllProduct] = useState([]);
  // const [allSpesKolom, setAllSpesKolom] = useState([]);
  const [nilai, setNilai] = useState(null);
  const [idProduct, setIdProduct] = useState(null);
  const [idSpesKolom, setIdSpesKolom] = useState(null);
  const [namaKolom, setNamaKolom] = useState(null);

  useEffect(() => {
    async function fetch() {
      setProses(true);
      if (data && data.namaProduct) {
        // setInput({
        //   idProduct: data.idProduct,
        //   namaProduct: data.namaProduct,
        //   aplikasi: data.aplikasi,
        //   ukuran: data.ukuran,
        //   fitur: data?.fitur,
        // });
        setIdProduct(data.idProduct);
        setIdSpesKolom(data.idSpesKolom);
        setNilai(data.nilai);
      }
      if (!dataProduct || dataProduct.length === 0) await fetchDataProduct("");
      if (!dataSpesKolom || dataSpesKolom.length === 0)
        await fetchDataSpesKolom("");

      setProses(false);
    }
    fetch();
    API.get("/product")
      .then((data) => {
        console.log("HEHEHEHEH", data.data.data);
        setAllProduct(data.data.data);
      })
      .catch((err) => console.log("err", err));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  console.log("dataPrdataSpesKolomodcu", dataSpesKolom);
  // const handleChange = (e) => {
  //   setInput({ ...input, [e.target.name]: e.target.value });
  // };

  const submit = async () => {
    try {
      // if (
      //   !input.idProduct ||
      //   !input.namaProduct ||
      //   !input.aplikasi ||
      //   !input.ukuran
      // ) {
      //   Swal.fire({
      //     title: "Data incomplete",
      //     icon: "warning",
      //   });
      // } else {
      //   setProses(true);
      //   let formData = new FormData();

      //   formData.append("idProduct", input.idProduct);
      //   formData.append("namaProduct", input.namaProduct);
      //   formData.append("aplikasi", input.aplikasi);
      //   formData.append("ukuran", input.ukuran);
      //   if (input.fitur === null) {
      //     console.log("---?????");
      //     formData.append("fitur", " ");
      //   } else {
      //     formData.append("fitur", input.fitur);
      //   }

      //   if (data && data.namaProduct)
      //     await editTableSpesifikasi(formData, data.id);
      //   else await addTableSpesifikasi(formData);

      //   await refresh();
      //   close();
      //   setProses(false);
      if (!idProduct || !idSpesKolom || !nilai) {
        // Tambahkan logika atau feedback sesuai kebutuhan
        console.error("Harap isi semua kolom!");
        return;
      }
      setProses(true);
      await addTableSpesifikasi({ idProduct, idKolom: idSpesKolom, nilai });
      setIdSpesKolom(null);
      setNilai("");
      await refresh();
      // close();
      setProses(false);

      console.log("ini sinya: ", { idProduct, idSpesKolom, nilai });
      // }
    } catch (err) {}
  };
  const submitKolom = async () => {
    await addSpesKolom({ namaKolom });

    await fetchDataSpesKolom("");
    console.log(namaKolom);
    await refresh();

    console.log("namaKolom", namaKolom);
  };
  // console.log("input===", input);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          style={{
            backgroundColor: "white",
            boxShadow: 5,
            overflow: "hidden",
            paddingBottom: 50,
            position: "relative",
            padding: 30,
            borderRadius: 10,
            width: "90%",
            maxWidth: 700,
            maxHeight: "90%",
            overflowY: "auto",
          }}
        >
          <h3 style={{ marginTop: 0, marginBottom: 5 }}>
            Create New Spesifikasi
          </h3>
          <Divider />
          <FormControl fullWidth margin="normal">
            <InputLabel id="idProduct">Product Utama</InputLabel>
            <Select
              labelId="idProduct"
              id="idProduct"
              value={idProduct}
              label="idProduct"
              name="idProduct"
              onChange={(e) => setIdProduct(e.target.value)}
              disabled={proses}
            >
              {allProduct.length === 0 ? (
                <MenuItem value="" disabled>
                  No Option
                </MenuItem>
              ) : (
                allProduct.map((product, index) => (
                  <MenuItem value={product.id} key={`product ${index}`}>
                    {product.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <div
            style={{ display: "flex", alignItems: "center" }}
            margin="normal"
            className="flex"
          >
            <TextField
              label="Tambah Kolom"
              name="namaKolom"
              value={namaKolom}
              onChange={(e) => setNamaKolom(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
              disabled={proses}
              style={{ marginRight: 10 }} // Atur margin atau style lainnya sesuai kebutuhan
            />
            <Button
              variant="contained"
              onClick={() => {
                submitKolom();
                setNamaKolom("");
              }}
            >
              Tambah
            </Button>
          </div>

          <FormControl fullWidth margin="normal">
            <InputLabel id="idKolom">Nama Kolom</InputLabel>
            <Select
              labelId="idKolom"
              id="idKolom"
              value={idSpesKolom}
              label="idKolom"
              name="idKolom"
              onChange={(e) => setIdSpesKolom(e.target.value)}
              disabled={proses}
            >
              {dataSpesKolom.length === 0 ? (
                <MenuItem value="" disabled>
                  No Option
                </MenuItem>
              ) : (
                dataSpesKolom.map((spesKolom, index) => (
                  <MenuItem value={spesKolom.id} key={`spesKolom ${index}`}>
                    {spesKolom.namaKolom}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <TextField
            label="isi"
            name="nilai"
            value={nilai}
            onChange={(e) => setNilai(e.target.value)}
            margin="normal"
            variant="outlined"
            fullWidth
            disabled={proses}
          />
          <Grid style={{ textAlign: "right", marginTop: 20 }}>
            <Button onClick={close} color="inherit" style={{ marginRight: 20 }}>
              {data ? "Cancel" : "Close"}
            </Button>
            <Button onClick={submit} variant="contained">
              {data ? "Edit" : "Submit"}
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}

const mapDispatchToProps = {
  addTableSpesifikasi,
  editTableSpesifikasi,
  fetchDataProduct,
  fetchDataSpesKolom,
  addSpesKolom,
};

const mapStateToProps = ({ dataProduct, dataSpesKolom }) => {
  return {
    dataProduct,
    dataSpesKolom,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddProduct);
