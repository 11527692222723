import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import TableSpesifikasi from "../views/Product/TableSpesifikasi";

const Login = lazy(() =>
  import(/* webpackChunkName: "login" */ "../views/Login")
);
const Contact = lazy(() =>
  import(/* webpackChunkName: "contact" */ "../views/Contact")
);
const Reseller = lazy(() =>
  import(/* webpackChunkName: "location" */ "../views/Reseller")
);
const Brand = lazy(() =>
  import(/* webpackChunkName: "brand" */ "../views/Brand")
);
const CategoryProduct = lazy(() =>
  import(
    /* webpackChunkName: "categoryProduct" */ "../views/Product/CategoryProduct"
  )
);
const Product = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Product")
);
const Service = lazy(() =>
  import(/* webpackChunkName: "service" */ "../views/Service")
);
const AboutUs = lazy(() =>
  import(/* webpackChunkName: "aboutus" */ "../views/AboutUs")
);
const Event = lazy(() =>
  import(/* webpackChunkName: "event" */ "../views/Event")
);
const News = lazy(() => import(/* webpackChunkName: "news" */ "../views/News"));
const Blogs = lazy(() =>
  import(/* webpackChunkName: "news" */ "../views/Blogs")
);
const Banner = lazy(() =>
  import(/* webpackChunkName: "news" */ "../views/Banner")
);
const Meta = lazy(() => import(/* webpackChunkName: "news" */ "../views/Meta"));

function Routes() {
  return (
    <main>
      <Suspense fallback={<></>}>
        <Switch>
          <Route path="/signin" component={Login} />
          <AuthenticatedRoute path="/contact-us" component={Contact} />
          <AuthenticatedRoute path="/reseller" component={Reseller} />
          <AuthenticatedRoute path="/brand" component={Brand} />
          <AuthenticatedRoute
            path="/product/category"
            component={CategoryProduct}
          />
          <AuthenticatedRoute path="/product" component={Product} />
          <AuthenticatedRoute
            path="/table-spesifikasi"
            component={TableSpesifikasi}
          />
          <AuthenticatedRoute path="/service" component={Service} />
          <AuthenticatedRoute path="/about-us" component={AboutUs} />
          <AuthenticatedRoute path="/event" component={Event} />
          <AuthenticatedRoute path="/news" component={News} />
          <AuthenticatedRoute path="/blogs" component={Blogs} />
          <AuthenticatedRoute path="/banner" component={Banner} />
          <AuthenticatedRoute path="/meta-tag" component={Meta} />
          <Redirect from="/" to="/signin" exact />
        </Switch>
      </Suspense>
    </main>
  );
}

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Cookies.get("BPWTKNCMS") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default Routes;
