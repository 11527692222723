export function setUser(payload) {
  return {
    type: "SET_USER",
    payload,
  };
}

export function login(payload) {
  return {
    type: "LOGIN",
    payload,
  };
}

export function logout() {
  return {
    type: "LOGOUT",
  };
}

export function checkToken() {
  return {
    type: "CHECK_TOKEN",
  };
}

export function fetchDataContact(payload) {
  return {
    type: "FETCH_DATA_CONTACT",
    payload,
  };
}

export function deleteContactUs(payload) {
  return {
    type: "DELETE_CONTACT",
    payload,
  };
}

export function fetchDataLocation(payload) {
  return {
    type: "FETCH_DATA_LOCATION",
    payload,
  };
}

export function addLocation(payload) {
  return {
    type: "ADD_LOCATION",
    payload,
  };
}

export function deleteLocation(payload) {
  return {
    type: "DELETE_LOCATION",
    payload,
  };
}

export function editLocation(payload) {
  return {
    type: "EDIT_LOCATION",
    payload,
  };
}

export function fetchDataBrand(payload) {
  return {
    type: "FETCH_DATA_BRAND",
    payload,
  };
}

export function addBrand(payload) {
  return {
    type: "ADD_BRAND",
    payload,
  };
}

export function deleteBrand(payload) {
  return {
    type: "DELETE_BRAND",
    payload,
  };
}

export function editBrand(payload) {
  return {
    type: "EDIT_BRAND",
    payload,
  };
}

export function fetchDataCategoryProduct(payload) {
  return {
    type: "FETCH_DATA_CATEGORY_PRODUCT",
    payload,
  };
}

export function addCategoryProduct(payload) {
  return {
    type: "ADD_CATEGORY_PRODUCT",
    payload,
  };
}

export function deleteCategoryProduct(payload) {
  return {
    type: "DELETE_CATEGORY_PRODUCT",
    payload,
  };
}

export function editCategoryProduct(payload, id) {
  return {
    type: "EDIT_CATEGORY_PRODUCT",
    payload,
    id,
  };
}

export function fetchDataProduct(payload) {
  return {
    type: "FETCH_DATA_PRODUCT",
    payload,
  };
}

export function addProduct(payload) {
  return {
    type: "ADD_PRODUCT",
    payload,
  };
}

export function deleteProduct(payload) {
  return {
    type: "DELETE_PRODUCT",
    payload,
  };
}

export function editProduct(payload, id) {
  return {
    type: "EDIT_PRODUCT",
    payload,
    id,
  };
}

export function fetchDataService(payload) {
  return {
    type: "FETCH_DATA_SERVICE",
    payload,
  };
}

export function addService(payload) {
  return {
    type: "ADD_SERVICE",
    payload,
  };
}

export function deleteService(payload) {
  return {
    type: "DELETE_SERVICE",
    payload,
  };
}

export function editService(payload, id) {
  return {
    type: "EDIT_SERVICE",
    payload,
    id,
  };
}

export function fetchDataAboutUs(payload) {
  return {
    type: "FETCH_DATA_ABOUT_US",
    payload,
  };
}

export function addAboutUs(payload) {
  return {
    type: "ADD_ABOUT_US",
    payload,
  };
}

export function deleteAboutUs(payload) {
  return {
    type: "DELETE_ABOUT_US",
    payload,
  };
}

export function editAboutUs(payload, id) {
  return {
    type: "EDIT_ABOUT_US",
    payload,
    id,
  };
}

export function fetchDataEvent(payload) {
  return {
    type: "FETCH_DATA_EVENT",
    payload,
  };
}

export function addEvent(payload) {
  return {
    type: "ADD_EVENT",
    payload,
  };
}

export function deleteEvent(payload) {
  return {
    type: "DELETE_EVENT",
    payload,
  };
}

export function editEvent(payload, id) {
  return {
    type: "EDIT_EVENT",
    payload,
    id,
  };
}

export function fetchDataNews(payload) {
  return {
    type: "FETCH_DATA_NEWS",
    payload,
  };
}

export function addNews(payload) {
  return {
    type: "ADD_NEWS",
    payload,
  };
}

export function deleteNews(payload) {
  return {
    type: "DELETE_NEWS",
    payload,
  };
}

export function editNews(payload, id) {
  return {
    type: "EDIT_NEWS",
    payload,
    id,
  };
}
// BLOG
export function fetchDataBlogs(payload) {
  return {
    type: "FETCH_DATA_BLOGS",
    payload,
  };
}

export function addBlogs(payload) {
  return {
    type: "ADD_BLOGS",
    payload,
  };
}

export function deleteBlogs(payload) {
  return {
    type: "DELETE_BLOGS",
    payload,
  };
}

export function editBlogs(payload, id) {
  return {
    type: "EDIT_BLOGS",
    payload,
    id,
  };
}

export function fetchDataBanner(payload) {
  return {
    type: "FETCH_DATA_BANNER",
    payload,
  };
}

export function addBanner(payload) {
  return {
    type: "ADD_BANNER",
    payload,
  };
}

export function deleteBanner(payload) {
  return {
    type: "DELETE_BANNER",
    payload,
  };
}

export function editBanner(payload, id) {
  return {
    type: "EDIT_BANNER",
    payload,
    id,
  };
}

export function fetchDataMetaTag(payload) {
  return {
    type: "FETCH_DATA_META_TAG",
    payload,
  };
}

export function addMetaTag(payload) {
  return {
    type: "ADD_META_TAG",
    payload,
  };
}

export function deleteMetaTag(payload) {
  return {
    type: "DELETE_META_TAG",
    payload,
  };
}

export function editMetaTag(payload, id) {
  return {
    type: "EDIT_META_TAG",
    payload,
    id,
  };
}

export function fetchDataSpesifikasi(payload) {
  return {
    type: "FETCH_DATA_SPESIFIKASI",
    payload,
  };
}

export function addSpesifikasi(payload) {
  return {
    type: "ADD_SPESIFIKASI",
    payload,
  };
}

export function deleteSpesifikasi(payload) {
  return {
    type: "DELETE_SPESIFIKASI",
    payload,
  };
}

export function editSpesifikasi(payload, id) {
  return {
    type: "EDIT_SPESIFIKASI",
    payload,
    id,
  };
}
export function fetchDataTableSpesifikasi(payload) {
  return {
    type: "FETCH_DATA_TABLE_SPESIFIKASI",
    payload,
  };
}

export function addTableSpesifikasi(payload) {
  return {
    type: "ADD_TABLE_SPESIFIKASI",
    payload,
  };
}

export function deleteTableSpesifikasi(payload) {
  return {
    type: "DELETE_TABLE_SPESIFIKASI",
    payload,
  };
}

export function editTableSpesifikasi(payload, id) {
  return {
    type: "EDIT_TABLE_SPESIFIKASI",
    payload,
    id,
  };
}
export function fetchDataSpesKolom(payload) {
  return {
    type: "FETCH_DATA_SPES_KOLOM",
    payload,
  };
}

export function addSpesKolom(payload) {
  return {
    type: "ADD_SPES_KOLOM",
    payload,
  };
}

export function deleteSpesKolom(payload) {
  return {
    type: "DELETE_SPES_KOLOM",
    payload,
  };
}

export function editSpesKolom(payload, id) {
  return {
    type: "EDIT_SPES_KOLOM",
    payload,
    id,
  };
}
