import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { MainGrid } from "../styleComponent";
import ModalAddTableSpesifikasi from "./ModalAddTableSpesifikasi";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import Swal from "sweetalert2";

import {
  fetchDataTableSpesifikasi,
  deleteTableSpesifikasi,
  editTableSpesifikasi,
} from "../../store/action";
import ModalTableProduct from "../../components/ModalTableProduct";
import ModalDetailSpesifikasi from "./ModalDetailSpesifikasi";

function Product({
  fetchDataTableSpesifikasi,
  dataTableSpesifikasi,
  totalTableSpesifikasi,
  deleteTableSpesifikasi,
  editTableSpesifikasi,
}) {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState(null);
  const [openModalAddProduct, setOpenModalAddProduct] = useState(false);
  const [openModalDetailSpesifikasi, setOpenModalDetailSpesifikasi] =
    useState(false);
  const [openModalTableProduct, setOpenModalTableProduct] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const [proses, setProses] = useState(false);
  const [filterSelected, setFilterSelected] = useState(null);

  useEffect(() => {
    fetchData(rowsPerPage, page, keyword, filterSelected);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async (limit, page, keyword, filter) => {
    let query = `?limit=${limit}&page=${page}`;
    if (keyword) query += `&keyword=${keyword}`;
    if (filter !== null) query += `&active=${filter}`;

    setProses(true);
    await fetchDataTableSpesifikasi(query);
    setProses(false);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
    fetchData(e.target.value, 0, keyword, filterSelected);
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    fetchData(rowsPerPage, newPage, keyword, filterSelected);
  };

  const handleChangeKeyword = (event) => {
    setPage(0);
    setKeyword(event.target.value);
    fetchData(rowsPerPage, 0, event.target.value, filterSelected);
  };

  const handleOpenAddProduct = () => {
    if (openModalAddProduct) setDataSelected(null);
    setOpenModalAddProduct(!openModalAddProduct);
  };

  const refresh = () => {
    setPage(0);
    fetchData(rowsPerPage, 0, keyword, filterSelected);
  };

  const deleteData = (id) => {
    Swal.fire({
      title: "Delete Product?",
      showCancelButton: true,
      confirmButtonText: `Delete`,
      cancelButtonText: `Cancel`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteTableSpesifikasi(id);
        await refresh();
        Swal.fire("Success deleted!", "", "success");
        refresh();
      }
    });
  };

  const updateData = (item) => {
    setDataSelected(item);
    handleOpenAddProduct();
  };

  const handleOpenDetailSpesifikasi = (item) => {
    if (openModalAddProduct) setDataSelected(null);
    else setDataSelected(item);
    setOpenModalDetailSpesifikasi(!openModalDetailSpesifikasi);
  };
  console.log("dataTableSpesifikasi", dataTableSpesifikasi);
  // const handleChange = (id) => async (e) => {
  //   try {
  //     let status = e.target.checked;
  //     let formData = new FormData();
  //     formData.append("active", status);
  //     await editTableSpesifikasi(formData, id);

  //     fetchData(rowsPerPage, page, keyword, filterSelected);
  //     Swal.fire(
  //       status ? "Active product success" : "Inactive product success",
  //       "",
  //       "success"
  //     );
  //   } catch (err) {
  //     Swal.fire("Please try again", "", "error");
  //   }
  // };

  // const handleFilter = (args) => () => {
  //   setFilterSelected(args);
  //   setPage(0);
  //   fetchData(rowsPerPage, 0, keyword, args);
  // };

  return (
    <MainGrid>
      <TextField
        label="Search name"
        variant="outlined"
        size="small"
        style={{ width: 400, marginBottom: 20 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={keyword}
        onChange={handleChangeKeyword}
      />
      <Grid style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
        <Typography variant="h5" component="div" style={{ marginRight: 20 }}>
          List Table Spesifikasi Product
        </Typography>
        <Button variant="contained" onClick={handleOpenAddProduct}>
          Create New
        </Button>
      </Grid>

      <div style={{ maxWidth: 1000 }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "10%" }}>No</TableCell>
                <TableCell style={{ width: "30%" }}>Produk Utama</TableCell>
                {/* <TableCell style={{ width: "30%" }}>Produk</TableCell> */}

                <TableCell style={{ width: "15%" }}>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!proses &&
                dataTableSpesifikasi
                  .filter((item) => item.deleted === null)
                  .map((item, index) => (
                    <TableRow key={`product ${index}`}>
                      <TableCell>
                        {(page + 1) * rowsPerPage - rowsPerPage + (index + 1)}
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      {/* <TableCell>{item.SpesKolom.namaKolom}</TableCell> */}
                      {/* <TableCell>{item.nilai}</TableCell> */}
                      {/* <TableCell>{item.ukuran}</TableCell>
                    <TableCell>{item.fitur}</TableCell> */}
                      <TableCell>
                        <DescriptionIcon
                          style={{
                            cursor: "pointer",
                            margin: "0px 5px",
                            color: "blue",
                          }}
                          onClick={() => handleOpenDetailSpesifikasi(item)}
                        />
                        {/* <EditIcon
                          style={{
                            cursor: "pointer",
                            margin: "0px 5px",
                            color: "green",
                          }}
                          onClick={() => updateData(item)}
                        />
                        <DeleteIcon
                          style={{
                            cursor: "pointer",
                            margin: "0px 5px",
                            color: "red",
                          }}
                          onClick={() => deleteData(item.id)}
                        /> */}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            {proses ? (
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 80,
                  height: 80,
                }}
              >
                <CircularProgress style={{ width: 50, height: 50 }} />
              </Grid>
            ) : (
              dataTableSpesifikasi.length === 0 && <p>Tidak ada data</p>
            )}
          </Grid>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={totalTableSpesifikasi}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      {openModalAddProduct && (
        <ModalAddTableSpesifikasi
          open={openModalAddProduct}
          close={handleOpenAddProduct}
          data={dataSelected}
          refresh={refresh}
        />
      )}
      {openModalDetailSpesifikasi && (
        <ModalDetailSpesifikasi
          open={openModalDetailSpesifikasi}
          close={handleOpenDetailSpesifikasi}
          data={dataSelected}
          refresh={refresh}
        />
      )}
      {openModalTableProduct && (
        <ModalTableProduct
          open={openModalTableProduct}
          // close={handleOpenTableProduct}
          data={dataSelected}
          refresh={refresh}
        />
      )}
    </MainGrid>
  );
}

const mapDispatchToProps = {
  fetchDataTableSpesifikasi,
  deleteTableSpesifikasi,
  editTableSpesifikasi,
};

const mapStateToProps = ({ dataTableSpesifikasi, totalTableSpesifikasi }) => {
  return {
    dataTableSpesifikasi,
    totalTableSpesifikasi,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
